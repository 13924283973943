@import '../../utilities/lib.less';

div.bas-inputButton {
	&.withOptions {
		.optionTrigger.icon {
			> svg.preferences_scheduled .cog {
				.transform-origin (44% 42%);
			}

			> svg.preferences .cog {
				.transform-origin (57% 58%);
			}
		}
	}
}

.buttonline {
	div.bas-inputButton {

		.button,
		.button.defaultLink {
			.defaultButton ();
		}

		&.highlight .button {
			.highlightButton ();
		}
	}
}


div.bas-inputButton {

	.button,
	.button.defaultLink,
	.button.ghost,
	.button.ghost.defaultLink {
		.defaultGhostButton ();
	}

	&.highlight .button,
	&.highlight .button.ghost {
		.highlightGhostButton ();
	}

	&.withOptions {

		.button.withOptions,
		.optionTrigger.icon {
			float: left;
		}

		.button.withOptions {

			&,
			&:hover,
			&:active,
			&:focus {
				background-color: @PrimaryBackgroundColor;
				border: 2px solid @PrimaryCiColor ! important;
				border-right: 0 solid transparent ! important;
				border-top-left-radius: @DefaultBorderRadius;
				border-bottom-left-radius: @DefaultBorderRadius;
				border-bottom-right-radius: 0;
				border-top-right-radius: 0;
				color: @PrimaryCiColor;
				margin-right: 0;
				padding: 0 8px;
				line-height: 24px;
				width: auto;
				margin-bottom: 0;
			}
		}

		&.active {
			.button.withOptions {
				border: 2px solid @HighlightColor ! important;
				border-right: 0 solid transparent ! important;
			}

			.optionTrigger.icon {
				border: 2px solid @HighlightColor ! important;
				border-left: 0 solid transparent ! important;
			}
		}

		.optionTrigger.icon {
			height: 24px;
			width: 25px;
			border: 2px solid @PrimaryCiColor ! important;
			border-left: 0 solid transparent ! important;
			border-top-right-radius: @DefaultBorderRadius;
			border-bottom-right-radius: @DefaultBorderRadius;
			padding-left: 2px;
			margin-left: 0;
			box-sizing: content-box;

			&:before {
				content: "";
				height: 80%;
				width: 0;
				border-right: 2px solid @PrimaryCiColor;
				position: absolute;
				top: 10%;
				left: 0;
			}

			> svg.preferences .cog,
			> svg.preferences_scheduled .cog {
				transition-duration: 0.8s;
				transition-property: transform;
			}

			&:hover {

				svg.preferences .cog,
				svg.preferences_scheduled .cog {
					.rotate (360deg);
				}
			}

			.hoverContainer {
				left: -7px;
				top: initial;
				bottom: 15px;

				.buttonOptions {
					background-color: @PrimaryBackgroundColor;
					border-radius: 2px;
					border: 2px solid @HoverColor;

					&:before,
					&:after {
						width: 0;
						height: 0;
						border-style: solid;
					}

					&:before {
						left: 0;
						bottom: -11px;
						border-width: 10px 10px 0 10px;
						border-color: @HoverColor transparent transparent transparent;
					}

					&:after {
						left: 2px;
						bottom: -8px;
						border-width: 8px 8px 0 8px;
						border-color: @PrimaryBackgroundColor transparent transparent transparent;
					}

					.buttonOption.icon {
						width: auto;
						height: 25px;
						padding: 5px 10px 5px 5px;
						position: relative;
						color: @PrimaryCiColor;
						line-height: 26px;

						.iconContainer {
							width: 25px;
							height: 25px;
							background-color: @PrimaryCiColor;
							margin-right: 5px;
						}

						&:not(:first-child):after {
							content: "";
							position: absolute;
							top: 0;
							left: 5%;
							width: 90%;
							height: 0;
							margin: 0 auto;
							border-top: 1px solid @HoverColor;
						}

						&:hover {
							color: @HoverColor;

							.iconContainer {
								background-color: @HoverColor;
							}
						}
					}
				}
			}
		}
	}
}

.inactive {
	> .button,
	> .button:hover {
		background-color: @ButtonInactiveColor;
		cursor: default;
	}
}

a.button.componentAddOn {
	margin-top: 10px;
}

a.button {
	font-weight: normal;
}