@import '../../utilities/lib.less';
@Icon_Set: url("../../../@{Icon_Set_File}");
@Icon_Set_Black: url("../../../@{Icon_Set_File_Black}");

@ColorNormal: @ButtonColor;
@ColorHover: @HoverColor;
@ColorInactive: #c2c2c2;

:root,
::before {
	--svg-icon-color-normal: @ColorNormal;
	--svg-icon-color-hover: @ColorHover;
	--svg-icon-color-inactive: @ColorInactive;
}

.icon {
    display: block;
    height: 20px;
    width: 20px;
    margin-right: 2px;
    border: 0 none;

    .iconContainer {
        .iconContainerBehaviour();
    }

    &.inactive .iconContainer {
        background-color: @ButtonInactiveColor;
        cursor: default;
    }

    &.inactive:hover .iconContainer {
        background-color: @ButtonInactiveColor;
        cursor: default;
    }

    &:hover .iconContainer {
        background-color: @HoverColor;
    }

    &.toHome .iconContainer {
        background-position: @IconHome;
    }

    &.show,
    &.show_small {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconShow;
        }
    }

    &.new,
    &.new_small,
    &.add,
    &.add_small,
    &.add_userarea {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconNew;
        }
    }

    &.edit,
    &.edit_small,
    &.rename {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconEdit;
        }
    }

    &.favorite {
        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconFavorite;
        }
    }

    &.favorite_choosen {
        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconFavoriteChoosen;
        }
    }

    &.copy,
    &.copy_small {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconCopy;
        }
    }

    &.delete,
    &.delete_small {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconDelete;
        }
    }

    &.assignment,
    &.assignment_small,
    &.combination {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconAssign;
        }
    }

    &.contactEdit,
    &.contactEdit_small {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconContactEdit;
        }
    }

    &.languages,
    &.languages_small {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconLanguages;
        }
    }

    &.take,
    &.take_small {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconTake;
        }
    }

    &.lock,
    &.lock_small {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconLock;
        }
    }

    &.unlock,
    &.unlock_small {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconUnlock;
        }
    }

    &.invisible,
    &.invisible_small {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconInvisible;
        }
    }

    &.visible,
    &.visible_small {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconVisible;
        }
    }

    &.shopping_cart,
    &.shopping_cart_small {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconShoppingCart;
        }
    }

    &.download,
    &.download_small {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconDownload;
        }
    }

    &.download_preview {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconDownloadPreview;
        }
    }

    &.create_preview {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconCreatePreview;
        }
    }

    &.download_grouped_preview {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconDownloadGroupedPreview;
        }
    }

    &.create_grouped_preview {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconCreateGroupedPreview;
        }
    }

    &.download_placeholder_preview {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconDownloadPlaceholderPreview;
        }
    }

    &.create_placeholder_preview {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconCreatePlaceholderPreview;
        }
    }

    &.create_final {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconCreateFinal;
        }
    }

    &.download_zusatz {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconDownloadZusatz;
        }
    }

    &.download_final {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconDownloadFinal;
        }
    }

    &.upload {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconUpload;
        }
    }

    &.link {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconLink;
        }
    }

	&:not(.pricelist-kompakt).pricelist {
		.iconContainer,
		&.inactive .iconContainer,
		&_inactive .iconContainer {
			background-position: @IconPrice;
		}
	}

	&.pricelist-kompakt.pricelist {
		&,
		&_inactive {
			width: 28px;
			height: 28px;

			&:hover::before {
				background-color: var(--svg-icon-color-hover);
			}

			&::before {
				@Size: 24px;
				display: inline-block;
				width: @Size;
				height: @Size;
				content: "";
				mask-repeat: no-repeat;
				cursor: pointer;
				background-clip: padding-box;
				background-color: var(--svg-icon-color-normal);
				mask: url("../../../img/icons/icon_euro.svg");
			}
		}

		&_inactive::before {
			background-color: var(--svg-icon-color-inactive);
			cursor: default;
		}
	}

    &.openAll,
    &.expand_small {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconPlus;
        }
    }

    &.closeAll,
    &.collapse_small {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconMinus;
        }
    }

    &.cut {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconCut;
        }
    }

    &.paste {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconPaste;
        }
    }

    &.move_first {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconMoveTop;
        }
    }

    &.move_last {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconMoveBottom;
        }
    }

    &.moveUp,
    &.move_up,
    &.move_up_small {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconMoveUp;
        }
    }

    &.moveDown,
    &.move_down,
    &.move_down_small {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconMoveDown;
        }
    }

    &.moveLeft,
    &.move_left_inactive {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconMoveLeft;
        }
    }

    &.moveRight,
    &.move_right_inactive {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconMoveRight;
        }
    }

    &.next,
    &.prev {
        .iconContainerBehaviour();

        &:hover {
            background-color: @HoverColor;
        }
    }

    &.next {
        background-position: @IconMoveRight;
    }

    &.prev {
        background-position: @IconMoveLeft;
    }

    &.next {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconMoveRight;
        }
    }

    &.prev {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconMoveLeft;
        }
    }

    &.continueForward {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: @IconMoveRight;
        }
    }

    &.destroyDialog,
    &.closeDialog {
        .iconContainer {
            background-position: @IconClose;
        }
    }

    &.history {
        .iconContainer {
            background-position: @IconHistory;
        }
    }

    &.superViewClose {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;

        .iconContainer {
            background-position: @IconClose;
        }
    }

    &.sortUp {
        .iconContainer {
            background-position: @IconUp;
        }
    }

    &.sortDown {
        .iconContainer {
            background-position: @IconDown;
        }
    }

    &.sortUp,
    &.sortDown {
        &.active .iconContainer {
            background-color: @HighlightColor;
        }
    }

    &.star {

        .iconContainer,
        &.inactive .iconContainer,
        &_inactive .iconContainer {
            background-position: 20px 20px;
        }
    }

    &.redo_small {

        .iconContainer,
        &.inactive .iconContainer,
        &.inactive .iconContainer {
            background-position: @IconRedo;
        }
    }

    &.redo_small.resetFilter,
    &.resetFilter {

        .iconContainer,
        &.inactive .iconContainer,
        &.inactive .iconContainer {
            background-position: @IconReset;
        }
    }

    &.mapshow {

        .iconContainer,
        &.inactive .iconContainer,
        &.inactive .iconContainer {
            background-position: @IconMap;
        }
    }

    &.clockpicker-trigger {
        margin-right: 0;

        .iconContainer,
        &.inactive .iconContainer,
        &.inactive .iconContainer {
            background-position: @IconClockpicker;
        }
    }

    &.tooltipTrigger {
        margin-top: 2px;

        .iconContainer {
            background-position: @IconTooltip;
        }
    }

    &.favIcon {
        .iconContainer {
            height: 20px;
            width: 20px;
            background-color: transparent;
            background-image: url("../../../img/icons/star_icon_sprite.png");
            background-position: 0 0;

            &:hover {
                background-color: transparent;
                background-position: -20px 0;
            }
        }

        &.manually {

            &,
            &:hover {
                .iconContainer {
                    background-position: -0px 0;
                }
            }
        }

        &.isFavorite {
            .iconContainer {
                background-position: -40px 0;
            }

            &:hover {
                .iconContainer {
                    background-position: 0 0;
                }
            }

            &.manually {

                &,
                &:hover {
                    .iconContainer {
                        background-position: -40px 0;
                    }
                }
            }
        }
    }

    &#copygridfamily {
        .iconContainer {
            background-position: 0 -20px;
            background-color: @HighlightColor;
        }
    }

    &#editcms {
        .iconContainer {
            background-position: @IconShow;
        }
    }

    &#editlocalizedtemplate {
        .iconContainer {
            background-position: @IconEdit;
        }
    }

    &#editlocalizedtemplate,
    &.toTemplate,
    &#editcms {
        .iconContainer {
            background-color: @HighlightColor;
        }

        &:hover {
            color: @HighlightColor;

            span {
                border-bottom-color: @HighlightColor;
            }
        }
    }

    &.toTemplate {
        .iconContainer {
            background-position: @IconEdit;
        }

        &.mode_admin {
            .iconContainer {
                background-position: @IconShow;
            }
        }

        &.crop_selection {
            .iconContainer {
                background-position: @IconEdit;
            }
        }
    }

    &#copylocalizedfamily {
        .iconContainer {
            background-position: @IconCopy;
        }
    }

    &#addtohomepage {
        .iconContainer {
            background-position: @IconHome;
        }
    }

    &.show_small.cms {
        .iconContainer {
            background-position: @IconEye;
        }
    }

    &.addTemplateLink,
    &.addNew {
        .iconContainer {
            background-position: @IconPlus;
        }
    }

    &#deletelocalizedfamilydialog {
        .iconContainer {
            background-position: @IconDelete;
        }
    }
}

a.icon.inactive {
    cursor: default;
}

a,
div {
    &.icon.text {
        width: auto;
        line-height: 22px;
    }
}

div.icon {
    &.inactive.text {
        color: @ButtonInactiveColor  ! important;
        line-height: 22px;
        cursor: default ! important;
        border-bottom: 1px solid transparent;

        &.button {
            color: @ButtonFontInactiveColor  ! important;
        }
    }

    &.bookable,
    &.notBookable {
        background-image: @Icon_Set_Black;
        background-position: @IconBookable;
    }

    &.notBookable {
        background-position: @IconNotBookable;
    }
}

.icon,
.item.horizontal .icon {
    display: inline-block;
}

div.boxGrid {
    .icon {
        display: inline-block;
        cursor: pointer;
        width: auto;
        height: auto;
    }

    .box {

        >a,
        >div:not(.tooltip) {
            display: table;
        }
    }
}

div.box {

    a,
    div.icon {
        padding: 0 0 0 0;
        margin: 0 0 @GuttingDefault 0;

        &.inactive.text {
            border-bottom: 0;
        }
    }
}

a.icon.moveUp,
a.icon.moveDown,
a.icon.moveLeft,
a.icon.moveRight,
div.icon.move_up_inactive,
div.icon.move_down_inactive,
div.icon.move_left_inactive,
div.icon.move_right_inactive {
    height: 20px;
    width: 20px;
}

.jsTreeControls {

    a.icon.moveUp,
    a.icon.moveDown,
    div.icon.move_up_inactive,
    div.icon.move_down_inactive {
        margin: 0 auto;
    }
}

.tabulation tr td {

    .icon.moveDown,
    .icon.moveUp {
        margin-right: 2px;
    }
}

.sortIcons .icon {
    margin: 2px;
}

a.icon.moveLeft,
div.icon.move_left_inactive {
    float: right;
}

.overlay.superView .icon.text {
    margin-top: @GuttingDefault;
    padding-bottom: 0;
}

td.col>.icon {
    display: inline-block;
    padding: 0;

    &.text {
        margin-right: 15px;
    }
}

img.ui-datepicker-trigger,
.clockpicker-trigger {
    background-color: @ButtonColor;
    border-top-right-radius: @DefaultBorderRadius;
    border-bottom-right-radius: @DefaultBorderRadius;
    border-top: 1px solid @PrimaryLayoutingColor;
    border-right: 1px solid @PrimaryLayoutingColor;
    border-bottom: 1px solid @PrimaryLayoutingColor;
    border-left: 0 none;
    padding: 1px 2px 2px;
    width: 25px;
    height: 25px;
}

img.ui-datepicker-trigger:hover,
.clockpicker-trigger:hover {
    background-color: @HoverColor;
}

input.datepicker[disabled] {
    +img.ui-datepicker-trigger {
        background-color: @ButtonInactiveColor;
        opacity: 1 ! important;

        &:hover {
            border-color: @PrimaryLayoutingColor;
        }
    }

    &:hover+img.ui-datepicker-trigger {
        border-color: @PrimaryLayoutingColor;
    }
}

input.clockpicker {

    +.clockpicker-trigger,
    +.clockpicker-trigger>div.iconContainer {
        cursor: pointer;
    }
}

input.clockpicker[disabled="true"] {

    +.clockpicker-trigger,
    +.clockpicker-trigger>div.iconContainer {
        background-color: @ButtonInactiveColor;
        cursor: default;

        &:hover {
            border-color: @PrimaryLayoutingColor;
        }
    }
}

a.openDialog.pricelist.icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: @GuttingDefault;
    border-radius: 1px;
    background-repeat: no-repeat;
    background-color: @ButtonColor;
    background-image: @Icon_Set;
    background-position: -160px 0;

    &:hover {
        background-color: @HoverColor;
    }
}

.buttonOption.icon {
    .iconContainer {
        background-color: @PrimaryCiColor;
        background-position: 0 0;
    }

    &.delete {
        .iconContainer {
	        background-image: url("../../../img/icons/btnOption_delete.png");
        }
    }

    &.edit {
        .iconContainer {
	        background-image: url("../../../img/icons/btnOption_edit.png");
        }
    }

    &.take {
        .iconContainer {
	        background-image: url("../../../img/icons/btnOption_take.png");
        }
    }
}

#favoriteMark~div.checkbox.favoriteMark {
    height: 20px;
    width: 20px;
    background-color: transparent;
	background-image: url("../../../img/icons/star_icon_sprite.png");
    background-position: 0 0;
    cursor: pointer;
    padding: 0;
    margin: 0;

    &:hover {
        background-position: -40px 0;
    }

    &.checked {
        background-position: -40px 0;

        &:hover {
            background-position: 0 0;
        }
    }
}

.sourceDestinationSet {
    .controls {

        .add,
        .remove {
            margin: 5px;

            a {
                float: none;
                background-repeat: no-repeat;
                background-color: @ButtonColor;
                background-image: @Icon_Set;
                height: 20px;
                width: 20px;
                cursor: pointer;

                &:hover {
                    background-color: @HoverColor;
                }

                &.inactive {

                    &,
                    &:hover {
                        background-color: @ButtonInactiveColor;
                        cursor: default;
                    }
                }
            }
        }

        .add a {
            background-position: @IconMoveRight;
        }

        .remove a {
            background-position: @IconMoveLeft;
        }
    }

    .sourceWrapper .formsearch {
        padding-right: 23px;

        &+#searchfieldTrigger {
            width: 23px;
            height: 23px;
            background-position: 2px 1px;
            background-repeat: no-repeat;
            background-color: @ButtonColor;
            background-image: @Icon_Set;
            border-bottom: 0 none;
            right: 24px;
            top: 6px;
            cursor: pointer;

            &:hover {
                background-color: @HoverColor;
            }
        }
    }
}

.selectionGroup {
    .selection input {
        &:checked:disabled+label.selectionArea {
            svg {

                &#db-image,
                &#upload-image,
                &#default-image,
                &#db-image:hover,
                &#upload-image:hover,
                &#default-image:hover {
                    .stroke {
                        stroke: @PrimaryLayoutingColor;
                    }
                }

                &#no-image,
                &#no-image:hover {

                    .stroke,
                    .picture,
                    .circle {
                        stroke: @PrimaryLayoutingColor;
                    }
                }
            }
        }

        &:disabled+label.selectionArea {
            svg {

                &#db-image,
                &#upload-image,
                &#default-image,
                &#db-image:hover,
                &#upload-image:hover,
                &#default-image:hover {
                    .stroke {
                        stroke: @ButtonInactiveColor;
                    }
                }

                &#no-image,
                &#no-image:hover {

                    .stroke,
                    .picture,
                    .circle {
                        stroke: @ButtonInactiveColor;
                    }
                }
            }
        }
    }
}