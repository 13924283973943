@import "../../utilities/lib.less";
@import '../../variableProvider.less';

@Icon_Set: url("../../../@{Icon_Set_File}");
.tooltip, .errorTooltip {
	background-color: @PrimaryBackgroundColor;
	border: 1px solid @PrimaryLayoutingColor;
	border-bottom-left-radius: 0;
	max-width: 522px;

	&.topright {
		border-bottom-left-radius: 0;
	}
	&.topleft {
		border-bottom-right-radius: 0;
	}
	&.bottomright {
		border-top-left-radius: 0;
	}
	&.bottomleft {
		border-top-right-radius: 0;
	}
}

.tooltip:not(.errorTooltip).informationBlock {
	> div {
		&:first-child:not(:last-child) {
			margin-bottom: 5px;
		}

		&:not(:first-child) {
			margin-top: 10px;
		}

		&:not(:last-child) {
			padding-bottom: 10px;
			border-bottom: 1px solid @PrimaryLayoutingColor;
		}
	}

	.linkWrapper {
		white-space: nowrap;
	}

	&:before {
		border-right: 1px solid @PrimaryLayoutingColor;
		border-bottom: 1px solid @PrimaryLayoutingColor;
	}
}

.tooltipTrigger {
	&.icon, &.infoIcon {
		width: 20px;
		height: 20px;
		margin-right: @GuttingDefault;
		background-repeat: no-repeat;
		background-color: @ButtonColor;
		background-image: @Icon_Set;
		background-position: @IconTooltip;
		.transition(@DefaultButtonAnimation);

		&:hover {
			background-color: @HoverColor;
		}
	}

	&.infoIcon.warning {
		background-position: @IconWarning;
	}
}

a .tooltipTrigger.infoIcon {
	margin-left: 3px;
	margin-top: 0;
	vertical-align: top;
}

div.errorTooltip {
	max-width: 322px;
	display: flex !important;
	z-index: @Index_Tooltip !important;
	position: absolute;

	.errorcontainer {
		display: inline-block;
		max-width: 240px;
		min-width: 50px;
		text-align: left;
		overflow: hidden;
		padding: 0 10px;
		margin-bottom: 0;
		background-color: transparent;
	}

	.iconContainer {
		flex-shrink: 0;
		display: inline-block;
		width: 22px;
		height: 22px;
		border-radius: 100%;
		text-align: center;
		font-size: 20px;
		line-height: 22px;
		font-weight: bold;
	}

	&.tt_hasWarnings, &.tt_hasErrors {
		.errorcontainer {
			> div {
				&:first-child:not(:last-child) {
					margin-bottom: 5px;
				}

				&:not(:first-child) {
					margin-top: 10px;
				}

				&:not(:last-child) {
					padding-bottom: 10px;
				}
			}
		}
	}

	&.tt_hasWarnings {
		.errorcontainer {
			color: @HintColor;

			> div:not(:last-child) {
				border-bottom: 1px solid @HintColor;
			}
		}

		.iconContainer {
			border: 1px solid @HintColor;
			color: @HintColor;
		}
	}

	&.tt_hasErrors {
		border: 1px solid @ErrorColor;

		.errorcontainer {
			> div:not(:last-child) {
				border-bottom: 1px solid @ErrorColor;
			}

			color: @ErrorColor;
		}

		.iconContainer {
			border: 1px solid @ErrorColor;
			color: @ErrorColor;
		}
	}
}