@import '../utilities/lib.less';
.jq-toast-wrap {
	display: block;
	position: fixed;
	width: 500px;
	pointer-events: none ! important;
	margin: 0;
	padding: 0;
	letter-spacing: normal;
	z-index: @Index_Toast ! important;
	* {
		margin: 0;
		padding: 0;
	}

	&.bottom-left {
		bottom: 20px;
		left: 20px;
	}

	&.bottom-right {
		bottom: 20px;
		right: 40px;
	}

	&.top-left {
		top: 20px;
		left: 20px;
	}

	&.top-right {
		top: 20px;
		right: 40px;
	}
}

.jq-toast-single {
	display: block;
	padding: 10px;
	margin: 0 0 5px;
	border-radius: 4px;
	font-size: 14px;
	position: relative;
	pointer-events: all ! important;
	cursor: pointer;
	background-color: #444444;
	color: white;
	h2 {
		font-family: arial, sans-serif;
		font-size: 14px;
		margin: 0 0 7px;
		background: none;
		color: inherit;
		line-height: inherit;
		letter-spacing: normal;
	}

	a {
		color: #eee;
		text-decoration: none;
		font-weight: bold;
		border-bottom: 1px solid white;
		padding-bottom: 3px;
		font-size: 12px;
	}

	ul {
		margin: 0 0 0 15px;
		background: none;
		padding: 0;
		li {
			list-style-type: disc ! important;
			line-height: 17px;
			background: none;
			margin: 0;
			padding: 0;
			letter-spacing: normal;
		}
	}

	span {
		line-height: 23px;
		font-color: @PrimaryFontColor;
	}
}

.close-jq-toast-single {
	position: absolute;
	top: 3px;
	right: 7px;
	font-size: 14px;
	cursor: pointer;
}

.jq-toast-loader {
	display: block;
	position: absolute;
	bottom: 0;
	height: 5px;
	width: 0;
	left: 0;
	background: @ButtonColor;
}

.jq-toast-loaded {
	width: 100%;
}

.jq-toast-has-icon {
	padding: 27px 20px 30px 80px;
	background-repeat: no-repeat;
	background-position: 20px 20px;
}

.jq-toast-icon-success {
	border: 1px solid @SuccessColor;
	background-color: @PrimaryBackgroundColor;
	-webkit-box-shadow: 0 5px 10px #ABABAB;
	box-shadow: 0 5px 10px #ABABAB;
	color: @PrimaryFontColor;
	.jq-toast-loader {
		background: @SuccessColor;
	}

	i.toast-icon {
		background-image: url("../../../../default/img/toast_notification.png");
		background-color: @SuccessColor;
		height: 40px;
		width: 40px;
		position: absolute;
		top: 20px;
		left: 20px;
		background-size: 40px;
	}
}

