@import '../utilities/lib.less';

body {
	text-align: left;
	min-height: 100%;

	&.readonly {
		background: @InputInactiveColor;
		color: @InputFontInactiveColor;
	}

	.background {
		min-height: 100%;
		min-width: 100%;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
		position: absolute;
	}


	&:not(.changedBackground) {
		.background {
			background: @BackgroundMotive;
		}
	}
}


.ym-wrapper {
	background: @PrimaryBackgroundColor;
	width: @ContentFullWidth + 2 * @ContentWhiteSpace;
	margin: 0 auto;
	text-align: left;
}

.ym-wbox {
	padding: 0 @ContentWhiteSpace;
}

span + .dummyLink,
.dummyLink + span,
.cms-formattedlink span + .defaultLink,
.cms-formattedlink .defaultLink + span {
	margin-left: @GuttingDefault;
}

#topnav a {
	margin-left: 10px;
}

#main {
	clear: left;
	display: table;
	width: 100%;
}

.ym-hlist ul li {

	a,
	strong {
		color: @PrimaryFontColor;
	}

	a {

		&:hover,
		&:focus,
		&:active {
			color: @PrimaryCiColor;
		}
	}
}

.ym-col3 {
	float: right;
}

.formatnotfound {
	margin-top: 1em;
}

.admin {
	background: @TertiaryLayoutingColor;
	border-radius: 0;
}

.addFormat {
	margin-top: 2em;
}

.locationmapWrapper {
	border: 1px solid @PrimaryLayoutingColor;
}

.box.error {
	margin-bottom: 10px;

	.box.error {
		margin-bottom: 0;
	}
}

li {
	margin-left: 0;
}

.reproPrev {
	margin-bottom: 15px;
}

div {

	&.cmsplaceholderpicture,
	&.placeholderpicture {
		position: relative;
		background-color: @SecondaryLayoutingColor;
		margin: 3px;

		span.infotext {
			.translate (-50%, -50%);
			display: inline-block;
			top: 50%;
			left: 50%;
			position: absolute;
		}
	}

	&.placeholderpicture.pos {
		margin-right: 30px;
	}

	&.cmsplaceholderpicture {
		padding: 0;
	}

	&#nav {
		padding: @GuttingDefault;
		float: left;

		a {
			color: @PrimaryFontColor;
		}
	}

	&.automaticreload {
		display: none;
	}
}

.ui-lab.color-dummy {
	width: 200px;
	height: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	font-weight: bold;
}

#ui-lab- {
	&primaryCiColor {
		background-color: @PrimaryCiColor;
	}

	&secondaryCiColor {
		background-color: @SecondaryCiColor;
	}

	&tertiaryCiColor {
		background-color: @TertiaryCiColor;
	}

	&primaryFontColor {
		background-color: @PrimaryFontColor;
	}

	&primaryLayoutingColor {
		background-color: @PrimaryLayoutingColor;
	}

	&secondaryLayoutingColor {
		background-color: @SecondaryLayoutingColor;
	}

	&tertiaryLayoutingColor {
		background-color: @TertiaryLayoutingColor;
	}

	&errorColor {
		background-color: @ErrorColor;
	}

	&successColor {
		background-color: @SuccessColor;
	}

	&buttonColor {
		background-color: @ButtonColor;
	}

	&buttonFontInactiveColor {
		background-color: @ButtonFontInactiveColor;
	}

	&inactiveColor {
		background-color: @ButtonInactiveColor;
	}

	&hoverColor {
		background-color: @HoverColor;
	}

	&highlightColor {
		background-color: @HighlightColor;
	}

	&rectangleColor {
		background-color: @RectangleColor;
	}
}