@import "../utilities/lib.less";
div.mySet {
	border: 1px solid @SecondaryLayoutingColor;
	background-color: @PrimaryBackgroundColor;
	width: auto;
	max-height: 250px;
	height: 250px;
	overflow: auto;
	div.row {
		border-bottom: 1px solid @TertiaryLayoutingColor;
	}

	div.tabulation div.row {
		div.col {
			padding: 0;
			border: 0;
			vertical-align: middle;
			.icon {
				display: block;
				margin: 10px 0 10px 10px;
				padding: 0;
			}

			* {
				vertical-align: middle;
			}

			div.mySet div.tabulation div.row div.col span {
				float: none;
			}

			&.text {
				padding-left: 25px;
				width: 100%;
				> .icon {
					display: inline-block;
					padding-right: 10px;
					margin-left: 0;
				}
			}

			span {
				&.important {
					display: block;
				}

				&.bold {
					margin-bottom: 3px;
				}
			}

			&.last:first-child {
				padding: 14px 0 12px 7px;
			}

			div.tooltipTrigger {
				float: right;
				img {
					margin: 8px 14px 0 0;
				}
			}
		}

		div.last {
			padding-left: 0;
			padding-right: 10px;
			width: 100%;
		}
	}

	div.floatingContainer {
		float: left;
	}
}

* + html div.mySet {
	div.row {
		div.last {
			width: auto;
			height: 100%;
		}

		div.col {
			div.tooltipTrigger , div.floatingContainer {
				margin-top: 7px;
			}
		}
	}

	div.tabulation div.row div.last > span {
		line-height: 40px;
	}
}

div.item {
	&.buttonline.top , &.buttonline.mySet_footer {
		background-color: @TertiaryLayoutingColor;
		border-color: @SecondaryLayoutingColor;
		line-height: 0;
	}

	&.buttonline {
		&.top {
			margin-top: 0;
			span {
				font-weight: normal;
			}
		}

		&.top > a , &.mySet_footer > a {
			padding-bottom: 0;
		}
	}

	&.vertical div.mySet {
		margin: 0;
	}
}

section.bas-formSection {
	span.subtitle_normal {
		color: @PrimaryFontColor;
		text-transform: none;
		font-size: 18px;
		margin-bottom: 15px;
	}

	div.item div.itemBox div.bas-inputSelect select[size] {
		margin-bottom: 0;
	}
}

