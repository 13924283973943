@import "../utilities/lib.less";

.bas-formSection {
	line-height: @LineHeight;
	padding-top: 0;
	margin-bottom: 0;

	.bas-formSection {
		padding: 0;
		clear: both;

		.bas-formSection {
			padding: 0;
		}
	}

	.extraBottomSpacing {
		margin-bottom: 10px;
	}

	.bas-formBox {
		header {
			font-size: 13px;
			line-height: 17px;
			margin: 3px 0 6px;
			vertical-align: middle;
		}

		.bas-inputArea > span, .itemBox > span {
			line-height: 22px;
		}

		&.noLabel {
			background: none;
			width: 100%;
		}
	}

	.item {
		vertical-align: top;
	}

	.bas-formRow {
		display: block;
	}

	.inputSeparator {
		background-color: @SecondaryLayoutingColor;
		height: 1px;
		margin: @GuttingDefault 0;
	}

	.verticalSeparator {
		background-color: @PrimaryLayoutingColor;
		height: 100%;
		min-height: 20px;
		width: 1px;
		display: inline-block;
	}

	.bas-label label {
		display: block;
		line-height: 22px;
		padding-right: @GuttingDefault;
	}

	&.ym-grid {
		width: auto;
	}

	.requiredMarker {
		position: relative;

		&:before {
			content: "*";
			font-size: 25px;
			color: @HighlightColor;
			top: 0;
			left: -15px;
			position: absolute;
		}
	}

	.requiredlegend {
		margin-top: 1em;
		display: inline-block;
		margin-bottom: 1em;
	}
}

.highlightFlash {
	box-shadow: 0 0 10px 0 @HighlightColor;
}

.bas-formBox {
	width: 100%;
}

div.bas-formBox, .field {
	padding: @GuttingDefault 0 @GuttingDefault 10px;
	.box-sizing (border-box);
}

.ym-grid .bas-formBox {
	width: 100%;
}

.bas-label {
	width: @Cols;
	min-width: @Cols;
	height: auto;
}

.bas-inputArea {
	height: auto;
	margin-left: @Cols;

	.bas-inputCheck > * {
		float: none;
		display: table-cell;

		&.tooltip {
			display: none;
		}

		&.tooltipTrigger {
			display: inline-block;
		}
	}

	div.urlCheckerContainer {
		width: auto;
	}

	&.horizontal > * {
		float: left;
		margin-right: 2px;
	}

	&.sublabelContent > span {
		display: inline-block;
	}

	div.charcounter {
		font-size: 12px;
		line-height: 22px;
		text-align: right;
		color: @DarkGrey;
	}
}

div.checkboxArray {
	display: inline-block;
}

div.bas-inputCheck {
	margin-right: 10px;
	padding-bottom: @GuttingDefault;
	line-height: 22px;

	&.hasNoLabel {
		margin-right: 0;

		.checkbox, .radio {
			margin-right: 0;
		}
	}
}

.preferences-statusbox {
	padding: @GuttingDefault;

	.text {
		color: @ErrorColor ! important;
	}
}

.urlChecker {
	margin: 6px 0;
	font-size: 12px;
	display: block !important;
}

.contentContainer {
	> .bas-formSection {
		margin-top: 0;
		margin-bottom: 2*@GuttingDefault;
	}

	.posterviewImage {
		width: 640px;
	}

	section.bas-formSection div.field #preview {
		width: auto;
	}
}

.inputSeparator {
	margin: 0 0 10px 10px;
	height: 1px;
	background-color: @SecondaryLayoutingColor;
}

.sourceDestinationSet {
	.sourceWrapper {
		&:before {
			height: 35px;
		}

		.formsearch {
			top: 5px;
		}

		select {
			padding-top: 35px;
		}
	}

	> .controls {
		padding-top: 19px;
	}

	select {
		max-height: 250px;
	}
}

