@import "../../utilities/lib.less";

.overlay {
	div.close {
		border-radius: 3px;
		padding: 3px;
		height: 24px;
		width: 24px;
		margin: 24px;
	}

	.multiPageSelect {
		.imageContainer:hover img, .imageContainer img.current {
			border-color: @HoverColor;
		}

		.imageContainer {
			img {
				border-color: @SecondaryLayoutingColor;
			}

			&:hover img, img.current {
				border-color: @HoverColor;
			}
		}
	}

	.overviewArea {
		height: 100%;
	}

	.overviewArea, .overviewImage {
		background: transparent;
		filter: none;
		border: 0 none;
	}
}

@Icon_Edit-Toolbar: url("../../../@{Icon_Set_Edit_Toolbar}");
.overviewArea {
	margin-bottom: @GuttingDefault;
	border: 1px solid @SecondaryLayoutingColor;
	padding: 0;
	background: @PrimaryBackgroundColor;

	input[type=text] {
		width: 50px;
		height: 22px;
	}

	.bas-inputText {
		margin: 1px 4px 0 0;
	}
}

#inputmaskMain .overviewArea {
	.overviewButtons + .overviewImage, #webtmModeDecorator + .overviewImage {
		border-top: 1px solid @SecondaryLayoutingColor;
	}
}

.overviewButtons {
	padding: 10px;
	background-color: @TertiaryLayoutingColor;
	border: none;
	line-height: 0;

	a {
		line-height: 0
	}

	.overviewButton, .reload, .pdfsuperview, .mobilePreview, .desktopPreview {
		margin: 0 @GuttingDefault 0 0;
		width: auto;
		height: auto;

		&.inactive {
			.iconContainer {
				background-color: @ButtonInactiveColor;
				cursor: default;
			}
		}

		&.active {
			.iconContainer {
				background-color: @HighlightColor;
				cursor: default;
			}
		}

		&:hover .iconContainer {
			background-color: @HoverColor;
		}

		.iconContainer {
			background-repeat: no-repeat;
			background-color: @ButtonColor;
			background-image: @Icon_Edit-Toolbar;
			margin: 0;
			padding: 0;
			height: 24px;
			width: 24px;
			line-height: 0;
			cursor: pointer;
			border-radius: @BorderRadiusDefault;
		}
	}

	.pdfsuperview .iconContainer {
		background-position: -216px 0;
	}

	.reload .iconContainer {
		background-position: 0 0;
	}

	.mobilePreview .iconContainer {
		background-position: -288px 0;
	}

	.desktopPreview .iconContainer {
		background-position: -264px 0;
	}

	.zoomIn .iconContainer {
		background-position: -24px 0;
	}

	.zoomOut .iconContainer {
		background-position: -48px 0;
	}

	.zoomDynamic .iconContainer {
		background-position: -72px 0;
	}

	.scaleX .iconContainer {
		background-position: -96px 0;
	}

	.scaleY .iconContainer {
		background-position: -120px 0;
	}

	.allrectangles .iconContainer {
		background-position: -144px 0;
	}

	.pdfsuperview, .pdfsuperview_currentpage, .pdfsuperview_all {
		.iconContainer {
			background-position: -216px 0;
		}
	}

	.pdfDownload .iconContainer {
		background-position: -168px 0;
	}

	.pdfDownload_all .iconContainer {
		background-position: -192px 0;
	}

	.minimize .iconContainer {
		background-position: -240px 0;
	}

	.bas-inputText {
		margin: 0 @GuttingDefault 0 0;

		.zoomInput {
			width: 60px;
			height: 24px;
			margin: 0;
		}
	}

	div.verticalSeparator {
		margin-right: @GuttingDefault;
	}
}

.previewButtons {
	background: transparent;
	border: none;
	padding-bottom: 5px;
	line-height: 0;
	height: 24px;

	a {
		line-height: 0
	}

	.mobilePreview, .overviewButton {
		margin: 0 @GuttingDefault 0 0;
		width: auto;
		height: auto;

		.iconContainer {
			background-repeat: no-repeat;
			background-color: @ButtonColor;
			background-image: @Icon_Edit-Toolbar;
			background-position: -288px 0;
			margin: 0;
			padding: 0;
			height: 24px;
			width: 24px;
			line-height: 0;
			cursor: pointer;
			border-radius: @BorderRadiusDefault;
		}

		&:hover .iconContainer {
			background-color: @HoverColor;
		}

		&.inactive {
			.iconContainer {
				background-color: @ButtonInactiveColor;
				cursor: default;
			}
		}

		&.active {
			.iconContainer {
				background-color: @HighlightColor;
				cursor: default;
			}
		}
	}

	.mobilePreview .iconContainer {
		background-image: @Icon_Edit-Toolbar;
		background-position: -288px 0;
	}

	.desktopPreview, .overviewButton {
		margin: 0 @GuttingDefault 0 0;
		width: auto;
		height: auto;

		.iconContainer {
			background-repeat: no-repeat;
			background-color: @ButtonColor;
			background-image: @Icon_Edit-Toolbar;
			background-position: -264px 0;
			margin: 0;
			padding: 0;
			height: 24px;
			width: 24px;
			line-height: 0;
			cursor: pointer;
			border-radius: @BorderRadiusDefault;
		}

		&:hover .iconContainer {
			background-color: @HoverColor;
		}

		&.inactive {
			.iconContainer {
				background-color: @ButtonInactiveColor;
				cursor: default;
			}
		}

		&.active {
			.iconContainer {
				background-color: @HighlightColor;
				cursor: default;
			}
		}
	}

	.desktopPreview .iconContainer {
		background-image: @Icon_Edit-Toolbar;
		background-position: -264px 0;
	}
}

.overviewImage {
	&.standard, &.newsletter, &.pos {
		height: 500px;
	}

	&.newsletter {
		cursor: move;

		.preview-highlight {
			cursor: pointer;
		}
	}

	+ .overviewButtons {
		border-top: 1px solid @SecondaryLayoutingColor;
	}
}

#inputmaskMain .overviewImage, .pictureinfo {
	background-color: #ffffff;
	border: 0 none;

	div.image {
		box-sizing: content-box;
		border: 1px solid @LightGrey;
	}
}

.picturePreview {
	background: @PrimaryBackgroundColor;
	border: 1px solid @SecondaryLayoutingColor;
	padding: @GuttingDefault;

	div.item.buttonline {
		border: 0;
		background: none;
		padding: @GuttingDefault 10px 0;
	}

	.pictureinfo > .infotext {
		padding-left: 10px;
	}

	.item.vertical .item.buttonline a.show {
		padding-bottom: 0;
	}

	object#overview {
		width: 275px;
		height: 154px;
	}
}

.multiPageSelect .imageContainer img {
	position: relative;
	max-width: 100%;
}

#editMask {
	overflow: visible;

	&.ym-equalize .ym-gbox {
		padding: 0;
		margin: 0;
	}

	&.ym-grid {
		width: auto;
	}
}

#editMaskContainer {
	input[type=text], select, textarea, .inlineckeditor {
		&:not([class*="cols"]) {
			width: 100% ! important;
		}

		max-width: 100% !important;
	}

	.panelContent {
		div.bas-formBox {
			padding: 5px 0;
		}
	}

	div {
		&.bas-formBox {
			width: 100%;
			display: block;
		}

		&.item.horizontal {
			width: 100%;

			input[type="text"], .inlineckeditor {
				&:not([class*="cols"]) {
					width: 100% ! important;
				}
			}

			div.bas-inputCheck {
				margin: 0 0 0 3px
			}

			a.icon.delete_small {
				float: right;
			}
		}

		&.bas-lockCheckbox {
			float: right;
			margin-right: 0;
		}
	}

	.item.horizontal {
		input[type=text], select, textarea, .inlineckeditor {
			&:not([class*="cols"]) {
				width: auto;
			}
		}
	}

	.ckeditor > span {
		width: 100% ! important;
	}

	.widthAuto {
		width: auto ! important;
	}

	.bas-formSection {
		div.item.horizontal.ym-clearfix .itemBox {
			&:last-child {
				padding-right: 0;
			}
		}

		.bas-formBox header.groupSeparator, .groupSeparator {
			margin: 15px 0 7px 0;
			font-size: 18px;
		}
	}

	section.bas-formSection div.bas-inputArea {
		max-width: 468px;
	}

	.itemBox:has(input[type="checkbox"]) + .itemBox.contains-input {
		flex-grow: 1;
	}
}

.imgButton_container {
	outline: 2px solid @PrimaryCiColor;

	img {
		background-color: @PrimaryCiColor;
	}

	&:hover {
		outline-color: @HoverColor;

		img {
			background-color: @HoverColor;
		}
	}

	&.selected, &.selected[data-disabled]:hover {
		outline-color: @HighlightColor;

		img {
			background-color: @HighlightColor;
		}
	}

	.isSelected {
		background-color: @PrimaryBackgroundColor;
		border-color: @PrimaryBackgroundColor;
	}
}

#overviewContainer {
	padding: 0 0 0 20px;

	.imgButton {
		&:hover {
			border-color: @PrimaryCiColor;
		}

		&.selected, &.selected[data-disabled]:hover {
			border: 2px solid @PrimaryCiColor;
		}
	}
}

.overview {
	display: block;
	margin: 0 auto;
	border: 1px solid @SecondaryLayoutingColor;
}

.multiPagePreviewContainer {
	width: 162px;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;

	> img {
		margin: 9px 0;
	}
}

.scrollUp, .scrollDown {
	width: 100%;
	height: 22px;
	background-color: red;
}

div[id ^= "caret:"] {
	&.active > div, &.active {
		border-color: @RectangleColor;
	}

	&.active.focus, &.inactive:hover, &.active:hover {
		> div {
			border-color: @RectangleHighlightColor ! important;
		}
	}

	&.rotated {
		&.active {
			> div > div {
				background-color: @RectangleColor ! important;
			}
		}

		&.active.focus, &.active:hover, &.inactive:hover {
			> div > div {
				background-color: @RectangleHighlightColor ! important;
			}
		}
	}
}

div.dynamicZoomSelection {
	background: @PrimaryLayoutingColor;
	opacity: 0.75;
	border: 1px dotted @PrimaryBackgroundColor;
}
