@import '../../utilities/lib.less';

#infoPageContents div.container.news div.infoContainer h2 {
	float: left;
	margin-right: 15px;
}

.infoContainer {
	h2.news_headline_date {
		color: #666;
		font-size: 15px;
		line-height: 27px;
	}

	h2.news-headline-vertical-separator {
		display: none;
	}

	h2.news_headline_title {
		color: @PrimaryFontColor;
		font-size: 20px;
	}
}

.containerGroup .inputSeparator {
	margin: 20px 0;
}

.dialogHeader {
	h3 {
		margin-top: 0;
	}
}

.field .horizontal .vertical .first h2 {
	margin-top: 0;
}
