@import '../utilities/lib.less';
@LightboxLoader: url('../../img/lightbox_loader.gif');

div.imageLoaderOverlay .loader {
	background-image: @LightboxLoader;
}

div.overlay , .dialog div.overlay {
	.loader {
		background-image: @LightboxLoader;
	}

	&.ajax {
		background-image: url("../../../../default/img/overlay_background_dark.png");
		z-index: @Index_Overlay + 1;
	}
}

.overlay .progressMonitor.upload {
	.progressPercentageContainer {
		font-family: @DefaultFont;
		font-size: 40px;
		color: @SecondaryCiColor;
		span {
			padding: 0 10px;
		}
	}

	&.mode-circle {
		svg.radial-progress {
			.radial-progress-circle {
				stroke: @SecondaryCiColor;
			}
		}
	}

	&.mode-custom {
		.progressPercentageContainer {
			color: @White;
			font-size: 40px;
		}

		.customAnimationContainer {
			background-size: 501px 100px;
			height: 100px;
			width: 501px;
			position: relative;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			#customAnimation {
				height: 92px;
				width: 38px;
				position: absolute;
				bottom: 3px;
				left: 0;
				&.step-0 {
					background: transparent url("../../img/uploadProgress/0.png") bottom left no-repeat;
				}

				&.step-1 {
					background: transparent url("../../img/uploadProgress/1.png") bottom left no-repeat;
				}

				&.step-2 {
					background: transparent url("../../img/uploadProgress/2.png") bottom left no-repeat;
				}

				&.step-3 {
					background: transparent url("../../img/uploadProgress/3.png") bottom left no-repeat;
				}
			}

			&.boat {
				#customAnimation {
					bottom: 40px;
				}

				&:after {
					background-size: 250px 75px;
					width: 250px;
					height: 75px;
					display: inline-block;
					content: "";
					position: absolute;
					top: 0;
					right: -50px;
				}

				&.person {
					#customAnimation {
						background: transparent;
						display: none;
					}
				}
			}
		}
	}

	.progressBarContainer {
		height: 70px;
		.progressBar {
			background: @SecondaryCiColor;
		}
	}
}

