@import '../utilities/lib.less';

div.buttonline {
	margin: 20px 0 0 0;
	text-align: left;

	&:empty {
		margin: 0;
		padding: 0;
	}
}

html.lt-ie7 div.cols1 {
	width: 123px;
}

div.bas-inputArea {
	input {
		&[type="checkbox"], &[type="radio"] {
			&.cols1, &.cols2, &.cols3, &.cols4, &.cols5, &.cols6 {
				width: auto;
			}
		}
	}

	.cols1 {
		width: @ColsInput1;

		&.hasButton {
			width: 166px;
		}

		&.hasSmileypicker {
			width: unset;
		}
	}

	.cols2 {
		width: @ColsInput2;

		&.hasSmileypicker {
			width: unset;
		}
	}

	.cols3 {
		width: @ColsInput3;

		&.hasSmileypicker {
			width: unset;
		}
	}

	.cols4 {
		width: @ColsInput4;

		&.hasSmileypicker {
			width: unset;
		}
	}

	.cols5 {
		width: @ColsInput5;

		&.processed_tooltip {
			width: 678px;
		}

		&.hasSmileypicker {
			width: unset;
		}
	}

	.cols6 {
		width: @ColsInput6;

		&.processed_tooltip {
			width: 678px;
		}

		&.hasSmileypicker {
			width: unset;
		}
	}

	.cols_kopra_price {
		width: 90px;
	}

	img {
		&.cols1 {
			width: auto;
			max-width: @ColsInput1;
		}

		&.cols2 {
			width: auto;
			max-width: @ColsInput2;
		}

		&.cols3 {
			width: auto;
			max-width: @ColsInput3;
		}

		&.cols4 {
			width: auto;
			max-width: @ColsInput4;
		}

		&.cols5 {
			width: auto;
			max-width: @ColsInput5;
		}

		&.cols6 {
			width: auto;
			max-width: @ColsInput6;
		}
	}

	.cols1,
	.cols2,
	.cols3,
	.cols4,
	.cols5,
	.cols6 {
		&.radio, &.checkbox {
			width: auto;
		}
	}

	div.cols1,
	div.cols2,
	div.cols3,
	div.cols4,
	div.cols5,
	div.cols6 {
		margin-right: @GuttingDefault;

		&.charcounter {
			padding-right: 0;
			line-height: 22px;
		}

		&.hasSmileypicker {
			border-radius: @DefaultBorderRadius;
		}
	}

	.placeholderpicture.cols3 {
		box-sizing: border-box;
	}
}

.textAlignCenter {
	text-align: center;
}

#page {
	padding: @PagePadding;

	&.restricted {
		#sidebar {
			display: none;
		}

		#col3 {
			float: left;
			width: 100%;
		}

		#main {
			border-top: none;
		}
	}
}

.stepContainer {
	display: block;
	overflow: auto;
	margin-bottom: 10px;

	.stepItem {
		display: inline-block;
		float: left;
		margin-right: 15px;
		overflow: auto;

		.stepNumber {
			float: left;
			display: inline-block;
			border: 2px solid @ButtonInactiveColor;
			width: 30px;
			height: 30px;
			border-radius: 30px;
			text-align: center;
			vertical-align: initial;
			line-height: 30px;
			color: @ButtonInactiveColor;
		}

		.stepTitle {
			float: right;
			display: inline-block;
			line-height: 30px;
			border: 2px solid transparent;
			margin-left: 5px;
			color: @ButtonInactiveColor;
		}

		&.active {
			.stepNumber {
				border: 2px solid @PrimaryCiColor;
				color: @PrimaryCiColor;
			}

			.stepTitle {
				color: @PrimaryCiColor;
			}
		}
	}
}

.affiliationFrame {
	border: 1px solid @MediumGrey;
	padding: 10px;

	&.hidden {
		border: 0 none;
		padding: 0;
	}
}

.whitespace {
	&_bottom {
		margin-bottom: @WhiteSpaceDefault !important;
	}
}

.browserOutdatedImg {
	float: left;
	margin-top: 40px;
	margin-left: 20px;
}

.browserOutdatedText {
	float: right;
	margin-top: 20px;

	div {
		margin-top: 20px;
	}
}

.browserOutdatedWarn {
	color: @ErrorColor;
	font-weight: bold;
	text-decoration: none;
}