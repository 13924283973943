@import '../../utilities/lib.less';
div.item {
	&.prev-container, &.next-container{
		display: flex;
	}
	
	&.next-container > .itemBox{
	 	flex-grow: 1;
	}
	
	&.buttonline {
		margin: 0;
		padding: 10px 7px 10px;
		> * {
			margin: 0 15px 0 0;
		}

		&.top , &.mySet_footer {
			background-color: @TertiaryLayoutingColor;
			border: 1px solid @PrimaryLayoutingColor;
			border-top: 0;
			border-radius: 0 0 2px 2px;
		}

		&.top {
			border-bottom: 0;
			border-radius: 3px 3px 0 0;
			padding: @GuttingDefault 6px 6px;
			margin-top: @GuttingDefault;
			color: @PrimaryLayoutingColor;
			> * {
				display: inline-block;
			}
		}
	}

	&.horizontal {
		> span {
			line-height: 21px;
		}

		.itemBox {
			margin-bottom: 0;
			padding-right: 5px;
			&.contains-table{
				width: 100%
			}
		}
	}

	&.cols1-3 .itemBox:last-child {
		max-width: 544px;
	}
	
	&.vertical {
		input[type=text] , input[type=password], input[type=file] , select:not([size]) , textarea, .cke, .inlineckeditor {
			margin-bottom: 10px;
		}
		
		input.datepicker {
			float:none;
			
			+ .ui-datepicker-trigger {
				position: absolute;
			}
		}
		
		.item.horizontal {
			display: flex;
		}
	}
}

.itemBox {
	line-height: 22px;
	.bas-inputSelect {
		line-height: 0;
	}

	> img {
		margin-right: 5px;
	}

	.cols1.datepicker {
		margin-bottom: 0;
	}
}

.item.linkGroup {
	text-align: center;
}

.noLabel .item.horizontal > .itemBox {
	max-width: 1080px;
}

